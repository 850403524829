<template>
  <grid-item
    class="image-container"
    :static="item.static"
    :x="item.x"
    :y="item.y"
    :w="item.w"
    :h="item.h"
    :i="`grid_item_${item.image_captured_date}`"
  >
    <er-image
      :src="item.thumbnail_url"
      :key="`image_${item.image_captured_date}`"
      class="image-holder"
      :data-carousel="item.carousel_id"
      lazy
      @click="handleImageClick($event, item.carousel_id)"
    >
      <div
        slot="placeholder"
        class="image-placeholder__wrapper"
        v-loading="true"
      ></div>
      <div slot="default">Image Slot</div>
    </er-image>
    <div class="image-footer__wrapper">
      <slot name="text"></slot>
    </div>
  </grid-item>
</template>

<script>
import { GridItem } from "vue-grid-layout";
import { mapActions } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
          static: false
        };
      }
    }
  },
  data() {
    return {
      elementResized: 0
    };
  },
  components: { GridItem },
  methods: {
    ...mapActions("pondDetails/pondABWTab", {
      addComponentToVisible: "addComponentToVisible",
      changeImageViewerInitialIndex: "changeImageViewerInitialIndex"
    }),
    handleImageClick(event, carouselId) {
      this.addComponentToVisible("LARGE_IMAGE_VIEWER");
      this.changeImageViewerInitialIndex(carouselId);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  display: inline-flex;
  .image-holder {
    width: 100%;
    border-radius: 5px;
  }
  .image-placeholder__wrapper {
    height: 100%;
  }
  .image-footer__wrapper {
    width: 100%;
    position: absolute;
    padding: 2px 5px;
    box-sizing: border-box;
    bottom: 0px;
    opacity: 0.9;
    @include small-text;
    background: white;
  }
}
</style>
