<template>
  <dynamic-scroller-item
    :item="listOfItems"
    :active="active"
    :size-dependencies="[listOfItems]"
    :data-index="index"
    :typeField="`dynamic-scroller-item_type_${listOfItems.date}`"
    class="section-item-container"
  >
    <layout-toolbar class="section-item-header">
      <p>
        {{ $t("Comn_date") }}:
        {{
          dhm__formatByTZOnUTCWithLocale(
            listOfItems.date,
            upm__getFormatDateString
          )
        }}
      </p>
      <div class="filler"></div>
      <p>
        {{ ftm__capitalize(this.$t("shrimpsnap.day_abw")) }}:
        {{ ftm__formatEmpty(ftm__digitPrecision(listOfItems.day_abw, 2)) }} g
      </p>
    </layout-toolbar>

    <cs-grid-layout
      :layout.sync="listGridItems"
      :col-num="gridColumns"
      :row-height="100"
      :is-draggable="false"
      :is-resizable="false"
      :vertical-compact="true"
      :use-css-transforms="false"
      :prevent-collision="true"
      class="section-item-body"
    >
      <image-item
        v-for="item in listGridItems"
        :item="item"
        :key="`image_item_${item.image_captured_date}`"
        :is-visible="active"
      >
        <layout-toolbar slot="text" gap="0">
          <p>
            {{
              dhm__formatByTZOnUTCWithLocale(
                item.image_captured_date,
                upm__getFormatTimeString
              )
            }}
          </p>
          <div class="filler"></div>
          <p>{{ ftm__formatEmpty(ftm__digitPrecision(item.abw, 2)) }} g</p>
        </layout-toolbar>
      </image-item>
    </cs-grid-layout>
  </dynamic-scroller-item>
</template>

<script>
import { DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import csGridLayout from "./csGridLayout";
import imageItem from "./imageItem.vue";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [datesHandlerMixin, userPreferenceMixin, filtersMixin],
  props: {
    listOfItems: {
      type: Object,
      default: () => ({})
    },
    index: { type: Number, default: 0 },
    active: { type: Boolean, default: false }
  },
  data() {
    return {
      gridColumns: 8
    };
  },
  components: { DynamicScrollerItem, csGridLayout, imageItem },
  computed: {
    listGridItems: function () {
      if (!this.listOfItems.images) return [];
      const list = this.$lodash
        .cloneDeep(this.listOfItems.images || [])
        .reduce((acc, curr, index) => {
          if (acc.length === 0) {
            curr.x = 0;
            curr.y = 0;
            curr.w = 1;
            curr.h = 1;
            curr.static = true;
            acc.push(curr);
            return acc;
          }
          if (index % this.gridColumns === 0) {
            curr.y = acc[acc.length - 1].y + 1;
            curr.x = 0;
          } else {
            curr.x = acc[acc.length - 1].x + 1;
            curr.y = acc[acc.length - 1].y;
          }
          curr.w = 1;
          curr.static = true;
          curr.h = 1;
          acc.push(curr);
          return acc;
        }, []);
      return this.$lodash.cloneDeep(list);
    }
  }
};
</script>

<style lang="scss" scoped>
.section-item-header {
  // position: sticky;
  // top: 0;
  padding: 5px 10px;
  @include small-text;
  font-weight: 700;
}
</style>
