var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"ss-abw-image-view-container",staticClass:"image-list-container"},[(_vm.getThumbnailsBySelectedSortedOrder.length)?[_c('DynamicScroller',{staticClass:"scroller",attrs:{"items":_vm.getThumbnailsBySelectedSortedOrder,"min-item-size":145,"key-field":"date","buffer":130},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('section-item',{attrs:{"listOfItems":item,"index":index,"active":active}})]}}],null,false,3657504267)}),_vm._v(" "),(_vm.isLargeImageViewerVisible)?_c('image-preview',{ref:"large-image-viewer",staticClass:"large-image-viewer",attrs:{"append-to-body":true,"onClose":_vm.imageViewerOnCloseHandler,"urlList":_vm.getPhotoUrlsByThumbnailSortOrder,"initial-index":_vm.getImageViewerInitialIndex,"z-index":2002},scopedSlots:_vm._u([{key:"details",fn:function(ref){
var index = ref.index;
return [_c('strong',[_vm._v(_vm._s(_vm.ftm__capitalize(_vm.$t("shrimpsnap.captured_time"))))]),_vm._v("\n        :\n        "+_vm._s(_vm.dhm__formatByTZOnUTCWithLocale(
            _vm.getPhotoObjByThumbnailSortOrder[index].image_captured_date,
            (_vm.upm__getFormatDateString + " " + _vm.upm__getFormatTimeString)
          ))+"\n        "),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("shrimpsnap.abw_with_units")))]),_vm._v("\n        :\n        "+_vm._s(_vm.ftm__formatEmpty(
            _vm.ftm__digitPrecision(_vm.getPhotoObjByThumbnailSortOrder[index].abw)
          ))+"\n      ")]}}],null,false,1977476943)}):_vm._e()]:[_c('er-empty')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }