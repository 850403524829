<script>
import { GridLayout } from "vue-grid-layout";
import {
  compact,
  getLayoutItem,
  getAllCollisions
} from "vue-grid-layout/src/helpers/utils";
export default {
  extends: GridLayout,
  methods: {
    resizeEvent: function(eventName, id, x, y, h, w) {
      let l = getLayoutItem(this.layout, id);
      // GetLayoutItem sometimes return null object
      if (l === undefined || l === null) {
        l = { h: 0, w: 0 };
      }
      let hasCollisions;
      if (this.preventCollision) {
        const collisions = getAllCollisions(this.layout, { ...l, w, h }).filter(
          layoutItem => layoutItem.i !== l.i
        );
        hasCollisions = collisions.length > 0;
        // If we're colliding, we need adjust the placeholder.
        if (hasCollisions) {
          // adjust w && h to maximum allowed space
          let leastX = Infinity;
          let leastY = Infinity;
          collisions.forEach(layoutItem => {
            if (layoutItem.x > l.x) leastX = Math.min(leastX, layoutItem.x);
            if (layoutItem.y > l.y) leastY = Math.min(leastY, layoutItem.y);
          });
          if (Number.isFinite(leastX)) l.w = leastX - l.x;
          if (Number.isFinite(leastY)) l.h = leastY - l.y;
        }
      }
      if (!hasCollisions) {
        // Set new width and height.
        l.w = w || l.w;
        l.h = h || l.h;
      }
      if (eventName === "resizestart" || eventName === "resizemove") {
        this.placeholder.i = id;
        this.placeholder.x = x;
        this.placeholder.y = y;
        this.placeholder.w = l.w;
        this.placeholder.h = l.h;
        this.$nextTick(function() {
          this.isDragging = true;
        });
        // this.$broadcast("updateWidth", this.width);
        this.eventBus.$emit("updateWidth", this.width);
      } else {
        this.$nextTick(function() {
          this.isDragging = false;
        });
      }
      if (this.responsive) this.responsiveGridLayout();
      compact(this.layout, this.verticalCompact);
      this.eventBus.$emit("compact");
      this.updateHeight();
      if (eventName === "resizeend") this.$emit("layout-updated", this.layout);
    }
  }
};
</script>
