<template>
  <div
    ref="ss-abw-image-view-container"
    class="image-list-container"
    v-loading="isLoading"
  >
    <template v-if="getThumbnailsBySelectedSortedOrder.length">
      <DynamicScroller
        :items="getThumbnailsBySelectedSortedOrder"
        :min-item-size="145"
        class="scroller"
        key-field="date"
        :buffer="130"
      >
        <template v-slot="{ item, index, active }">
          <section-item
            :listOfItems="item"
            :index="index"
            :active="active"
          ></section-item>
        </template>
      </DynamicScroller>
      <image-preview
        ref="large-image-viewer"
        class="large-image-viewer"
        :append-to-body="true"
        :onClose="imageViewerOnCloseHandler"
        :urlList="getPhotoUrlsByThumbnailSortOrder"
        v-if="isLargeImageViewerVisible"
        :initial-index="getImageViewerInitialIndex"
        :z-index="2002"
      >
        <template v-slot:details="{ index }">
          <strong>{{ ftm__capitalize($t("shrimpsnap.captured_time")) }}</strong>
          :
          {{
            dhm__formatByTZOnUTCWithLocale(
              getPhotoObjByThumbnailSortOrder[index].image_captured_date,
              `${upm__getFormatDateString} ${upm__getFormatTimeString}`
            )
          }}
          <strong> {{ $t("shrimpsnap.abw_with_units") }}</strong>
          :
          {{
            ftm__formatEmpty(
              ftm__digitPrecision(getPhotoObjByThumbnailSortOrder[index].abw)
            )
          }}
        </template>
      </image-preview>
    </template>
    <template v-else>
      <er-empty></er-empty>
    </template>
  </div>
</template>

<script>
import sectionItem from "./sectionItem";
import ImagePreview from "./imagePreview.vue";
import { DynamicScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { mapActions, mapGetters } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [datesHandlerMixin, userPreferenceMixin, filtersMixin],
  components: { sectionItem, DynamicScroller, ImagePreview },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("pondDetails/pondABWTab", {
      getVisibleComponents: "getVisibleComponents",
      getPhotoUrlsByThumbnailSortOrder: "getPhotoUrlsByThumbnailSortOrder",
      getThumbnailsBySelectedSortedOrder: "getThumbnailsBySelectedSortedOrder",
      getImageViewerInitialIndex: "getImageViewerInitialIndex",
      getLoadingComponents: "getLoadingComponents",
      getPhotoObjByThumbnailSortOrder: "getPhotoObjByThumbnailSortOrder"
    }),
    isLargeImageViewerVisible: function () {
      return this.getVisibleComponents.has("LARGE_IMAGE_VIEWER");
    },
    isLoading() {
      return ["FETCH_SS_DETAILS", "FETCH_SS_IMAGE_DETAILS"].some((x) =>
        this.getLoadingComponents.has(x)
      );
    }
  },
  methods: {
    ...mapActions("pondDetails/pondABWTab", {
      removeComponentFromVisible: "removeComponentFromVisible"
    }),
    imageViewerOnCloseHandler() {
      this.removeComponentFromVisible("LARGE_IMAGE_VIEWER");
    }
  }
};
</script>

<style lang="scss" scoped>
.image-list-container {
  flex-grow: 1;
  min-height: 0;
  .scroller {
    height: 100%;
  }
}
</style>
